import banner from "../assets/liraBanner.jpg";

export const Header = ({ changeLangHandler = () => {} }) => {
  return (
    <div className="w-full px-4 py-3 flex flex-col gap-2">
      <div className="w-full flex gap-1">
        <span
          className="cursor-pointer text-sky-600"
          onClick={() => {
            changeLangHandler("ru");
          }}
        >
          RUS
        </span>
        <span className="">|</span>
        <span
          className="cursor-pointer text-sky-600"
          onClick={() => {
            changeLangHandler("en");
          }}
        >
          ENG
        </span>
      </div>
      <img src={banner} alt="banner" className="w-full block" />
    </div>
  );
};
